import { PROJECT_NAME, USER_ADDRESS, ADDRESS_KEY } from "../../constants";
import { connex } from "../../constants/contract"
import discordLogo from "../../images/5968968.png"

const Header = () => {
    const handleLogIn = async () => {
        connex
            .vendor
            .sign('cert', {
                purpose: 'identification',
                payload: {
                    type: 'text',
                    content: 'Please sign the certificate to connect to ' + PROJECT_NAME
                }
            })
            .request()
            .then(result => {
                localStorage.setItem(ADDRESS_KEY, result.annex.signer);
                document.location.href = "/";
            })
    };

    const handleLogout = () => {
        localStorage.removeItem(ADDRESS_KEY)
        document.location.href = "/";
    }

    return <header className="u-clearfix u-header u-header" id="sec-dcaf">
        <div className="u-clearfix u-sheet u-valign-middle-md u-valign-middle-xs u-sheet-1">
            <p className="u-custom-font u-text u-text-default u-text-1">VOGIES</p>
            {USER_ADDRESS === null
                ?
                <a className="u-border-2 u-border-black u-btn u-button-style u-hover-black u-none u-text-black u-text-hover-white u-btn-1"
                   onClick={handleLogIn}>connect wallet</a>
                :
                <a className="u-border-2 u-border-black u-btn u-button-style u-hover-black u-none u-text-black u-text-hover-white u-btn-1"
                   onClick={handleLogout}>{USER_ADDRESS.substr(0,4)}...{USER_ADDRESS.substr(38, 4)} <br /> Logout</a>
            }
            <nav className="u-menu u-menu-dropdown u-offcanvas u-menu-1" data-responsive-from="XL">
                <div className="menu-collapse" style={{ fontSize: '1rem', letterSpacing: '0px' }}>
                    <a className="u-button-style u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-top-bottom-menu-spacing u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="#" style={{ padding: '17px 38px', fontSize: 'calc(1em + 34px)' }}>
                        <svg className="u-svg-link" viewBox="0 0 24 24"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#menu-hamburger" /></svg>
                        <svg className="u-svg-content" version="1.1" id="menu-hamburger" viewBox="0 0 16 16" x="0px" y="0px" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"><g><rect y={1} width={16} height={2} /><rect y={7} width={16} height={2} /><rect y={13} width={16} height={2} />
                        </g></svg>
                    </a>
                </div>
                <div className="u-custom-menu u-nav-container">
                    <ul className="u-nav u-unstyled u-nav-1">
                        <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/" style={{ padding: '10px 20px' }}>Vogies Home</a></li>
                        <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/meet-the-team" style={{ padding: '10px 20px' }}>Meet the team</a></li>
                        <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/minting" style={{ padding: '10px 20px' }}>Vogies Minting</a></li>
                        <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/assets" style={{ padding: '10px 20px' }}>Your Vogies</a></li>
                        <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/spacedoutassets" style={{ padding: '10px 20px' }}>Your Spaced out Vogies</a></li>
                        <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="https://BlackVeMarket.com" target="_blank" style={{ padding: '10px 20px' }}>Aftermarket</a></li>
                    </ul>
                </div>
                <div className="u-custom-menu u-nav-container-collapse">
                    <div className="u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav">
                        <div className="u-inner-container-layout u-sidenav-overflow">
                            <div className="u-menu-close" />
                            <ul className="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-2">
                                <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/" style={{ padding: '10px 20px' }}>Home</a></li>
                                <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/meet-the-team" style={{ padding: '10px 20px' }}>Meet the team</a></li>
                                <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/minting" style={{ padding: '10px 20px' }}>Minting</a></li>
                                <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/assets" style={{ padding: '10px 20px' }}>Your Vogies</a></li>
                                <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/spacedoutassets" style={{ padding: '10px 20px' }}>Your Spaced out Vogies</a></li>
                                <li className="u-nav-item"><a className="u-button-style u-nav-link" href="https://BlackVeMarket.com" target="_blank" style={{ padding: '10px 20px' }}>Aftermarket</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="u-black u-menu-overlay u-opacity u-opacity-70" />
                </div>
            </nav>
            <div className="u-social-icons u-spacing-10 u-social-icons-1">
                <a className="u-social-url" title="twitter" target="_blank" href="https://twitter.com/VogiesNFT"><span className="u-icon u-social-icon u-social-twitter u-text-black u-icon-1"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-3ef1" /></svg><svg className="u-svg-content" viewBox="0 0 112 112" x={0} y={0} id="svg-3ef1"><circle fill="currentColor" className="st0" cx="56.1" cy="56.1" r={55} /><path fill="#FFFFFF" d="M83.8,47.3c0,0.6,0,1.2,0,1.7c0,17.7-13.5,38.2-38.2,38.2C38,87.2,31,85,25,81.2c1,0.1,2.1,0.2,3.2,0.2
c6.3,0,12.1-2.1,16.7-5.7c-5.9-0.1-10.8-4-12.5-9.3c0.8,0.2,1.7,0.2,2.5,0.2c1.2,0,2.4-0.2,3.5-0.5c-6.1-1.2-10.8-6.7-10.8-13.1
c0-0.1,0-0.1,0-0.2c1.8,1,3.9,1.6,6.1,1.7c-3.6-2.4-6-6.5-6-11.2c0-2.5,0.7-4.8,1.8-6.7c6.6,8.1,16.5,13.5,27.6,14
c-0.2-1-0.3-2-0.3-3.1c0-7.4,6-13.4,13.4-13.4c3.9,0,7.3,1.6,9.8,4.2c3.1-0.6,5.9-1.7,8.5-3.3c-1,3.1-3.1,5.8-5.9,7.4
c2.7-0.3,5.3-1,7.7-2.1C88.7,43,86.4,45.4,83.8,47.3z" /></svg></span>
                </a>
                <a className="u-social-url" title="Discord" target="_blank" href="https://discord.gg/7XjahpgaFf"><span className="u-file-icon u-icon u-social-icon u-social-instagram u-icon-2"><img src={discordLogo} alt="" /></span>
                </a>
            </div>
        </div>
    </header>
}

export default Header

import Connex from '@vechain/connex'
import {NODE, NETWORK, PROJECT_SMARTCONTRACT_ADDRESS, SPACEDOUT_SMARTCONTRACT_ADDRESS} from './'

const connex = new Connex({node: NODE, network: NETWORK})
const vogiescontract = connex.thor.account(PROJECT_SMARTCONTRACT_ADDRESS)
const spacedoutcontract = connex.thor.account(SPACEDOUT_SMARTCONTRACT_ADDRESS)

export {
    connex,
    vogiescontract,
    spacedoutcontract
}

import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Home from './pages/Home'
import Minting from './pages/Minting'
import Assets from './pages/Assets'
import NoPage from "./pages/NoPage";
import Team from "./pages/Team";
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import SpacedOutAssets from "./pages/SpacedOutAssets";

function App() {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route element={< Home />} path='/'/>
                <Route element={< Team />} path='/Meet-the-team'/>
                <Route element={< Minting />} path='/minting'/>
                <Route element={< Assets />} path='/assets'/>
                <Route element={< SpacedOutAssets />} path='/spacedoutassets' />
                <Route path="*" element={< NoPage />}/>
            </Routes>
            <ToastContainer />
            <Footer/>
        </BrowserRouter>
    );
}

export default App;

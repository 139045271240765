import { PROJECT_NAME, NFTRARITYTOOL, USER_ADDRESS } from "../constants";
import { ABI } from "../constants/abi";
import { useState, useEffect } from 'react';
import { vogiescontract, connex } from "../constants/contract"
import plaatje from './../images/F343C753-C957-4CD8-A6DC-55E26E4197F0.jpg'
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { toast } from "react-toastify";
import ReactPaginate from 'react-paginate';
import transferNft from "../components/functions/transferNft";
import getDataFromNftraritytool from "../components/functions/getDataFromNftraritytool";

const Assets = () => {
    const [loading, setLoading] = useState(true);
    const [metadata, setMetadata] = useState([]);
    const [recipient, setRecipient] = useState('');
    const [countVogiesInWallet, setCountVogiesInWallet] = useState(0)

    const findMethodABI = (ABI, method) => ABI[ABI.findIndex(mthd => mthd.name === method)]

    const balanceOfABI = findMethodABI(ABI, 'balanceOf');
    const tokenURIABI = findMethodABI(ABI, 'tokenURI')

    useEffect(async () => {
        if (USER_ADDRESS) {
            await loadAssets();
        }

        setLoading(false);
    }, [])

    const loadAssets = async (page = 1) => {
        let currentInWallet = await (vogiescontract.method(findMethodABI(ABI, 'walletOfOwner')).call(USER_ADDRESS));
        currentInWallet = currentInWallet.decoded[0].sort(function (a, b) {
            return a - b
        });
        setCountVogiesInWallet(currentInWallet.length)
        let metas = await getDataFromNftraritytool(currentInWallet, 'vogies')

        setMetadata(metas)

        setLoading(false);
        setMetadata([...metas])
    }

    const override = css`
        display: block;
        margin: 0 auto;
    `;

    const transfer = async (index) => {
        await transferNft(ABI, vogiescontract, recipient, metadata[index].result.edition)
        await loadAssets();
    }

    import('./../Assets.css')

    return <section className="u-clearfix your-vogies-u-section-1" id="sec-9c91">
        <div className="u-clearfix u-sheet u-sheet-1">
            <h1 className="u-custom-font u-text u-text-default u-title u-text-1">your vogies</h1>
            <h3 className="u-custom-font u-text u-text-default u-text-2">how many vogies do you have : {countVogiesInWallet}</h3>
            {metadata.map((token, index) =>
                <div key={index}>
                    <img className="u-image u-image-default u-image-1" src={token.img}
                        data-image-width="1200" data-image-height="1500" loading={"lazy"} />
                    <h3 className="u-align-center u-custom-font u-text u-text-default u-text-3">Your Vogies FIT :</h3>
                    <p className="u-text u-text-default u-text-4" style={{marginTop: '10px'}}>
                        <table width={'100%'}>
                            <tbody>
                                <tr>
                                    <td align={"right"} style={{fontWeight: '900'}}>id: </td>
                                    <td style={{ paddingLeft: '8px', fontWeight: '900' }}>{token.result.edition}</td>
                                </tr>
                                {token.result.attributes.map((attribute) =>
                                    <tr key={index + "-" + attribute.trait_type} style={{padding: '0', margin: '0'}}>
                                        <td align={"right"} style={{fontWeight: '900'}}>{attribute.trait_type}: </td>
                                        <td style={{ paddingLeft: '8px', fontWeight: '900' }}>{attribute.value}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </p>
                    <div className="u-form u-form-1" style={{marginTop: '10px'}}>
                        <div className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form"
                            style={{ padding: '10px' }}>
                            <div className="u-form-email u-form-group">
                                <input type="text" placeholder="Sync2/VeChainThor wallet address" id="email-51d4"
                                    name="Transfer"
                                    className="u-border-1 u-border-grey-30 u-input u-input-rectangle u-white"
                                    onChange={e => setRecipient(e.target.value)}
                                />
                            </div>
                            <div className="u-align-center u-form-group u-form-submit">
                                <button onClick={() => transfer(index)} className="u-border-none u-btn u-btn-submit u-button-style u-custom-color-3 u-btn-1" style={{ margin: '0' }}>Send</button>
                            </div>
                        </div>
                    </div>
                    <p className="u-text u-text-default u-text-5">
                        Disclaimer : Only send your VNFT to a official
                        VeChainThor network wallet like Sync 2 or VeChainThor Mobile wallet
                    </p>
                    <hr />
                </div>
            )}
        </div>
    </section>
};

export default Assets;

import djBurnz from "../images/LIWsadwN_400x400.jpg"
import Megzie from "../images/5bjX8fO1_400x400.jpg"
import Novo from "../images/FSdC0f5XsAEvYEl.jfif"
import Airworthy from "../images/2.png"


const Team = () => {
    import('./../Meet-the-team.css')
    return <div>
        <section className="u-align-center u-clearfix meet-u-section-1" id="sec-2b13">
            <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
                <div className="u-expanded-width u-list u-list-1">
                    <div className="u-repeater u-repeater-1">
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-2">
                                <img alt="" className="u-image u-image-default u-image-2" data-image-width="400"
                                     data-image-height="400" src={djBurnz} />
                                <h5 className="u-text u-text-3">
                                    <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-2"
                                       href="https://twitter.com/Dj_Burnz" target="_blank">DJ Burnz</a>
                                </h5>
                                <p className="u-text u-text-4">Artist</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-2">
                                <img alt="" className="u-image u-image-default u-image-2" data-image-width="400"
                                     data-image-height="400" src={Megzie} />
                                    <h5 className="u-text u-text-3">
                                        <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-2"
                                           href="https://twitter.com/MegzieStudio" target="_blank">Megzie</a>
                                    </h5>
                                    <p className="u-text u-text-4">Artist</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-3">
                                <img alt="" className="u-image u-image-default u-image-3" data-image-width="2048"
                                     data-image-height="2048" src={Novo} />
                                    <h5 className="u-text u-text-5">
                                        <a href="https://twitter.com/novocaineee"
                                           className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-3"
                                           target="_blank">Novo</a>
                                    </h5>
                                    <p className="u-text u-text-6">CEO</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-4">
                                <img alt="" className="u-image u-image-default u-image-4" data-image-width="1200"
                                     data-image-height="1500" src={Airworthy}/>
                                    <h5 className="u-text u-text-7">
                                        <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-4"
                                           href="https://twitter.com/UkiyoeWarriors" target="_blank">Airworthy</a>
                                    </h5>
                                    <p className="u-text u-text-8">Advisor</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    }
export default Team;

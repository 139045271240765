// const NODE = 'https://mainnet.veblocks.net/'
// const NETWORK = 'main'
// const PROJECT_SMARTCONTRACT_ADDRESS = '0x4A6B084243762dc219480Edc5cFa0d88298BB707'
// const SPACEDOUT_SMARTCONTRACT_ADDRESS = '0x0'
// const ADDRESS_KEY = 'address';
const NODE = 'https://testnet.veblocks.net/'
const NETWORK = 'test'
const PROJECT_SMARTCONTRACT_ADDRESS = '0x715FF65680C729Ac29E5402572083A7A4eA0f5B4'
const SPACEDOUT_SMARTCONTRACT_ADDRESS = '0xB2eeae80D9bC99d4C709c33D2ed339cD9a034319'
const PROJECT_NAME = 'Vogies'
const ADDRESS_KEY = 'address_testnet';

const USER_ADDRESS = window.localStorage.getItem(ADDRESS_KEY);

export {
    NODE,
    NETWORK,
    PROJECT_NAME,
    PROJECT_SMARTCONTRACT_ADDRESS,
    SPACEDOUT_SMARTCONTRACT_ADDRESS,
    USER_ADDRESS,
    ADDRESS_KEY
}

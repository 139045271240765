
import vogie from "../images/GOOGLE_DOC_VOGIE.jpg"
import roadmap from "../images/unknown.png"

const Home = () => {
    import('./../Home.css')
    return <div>
        <section className="u-clearfix home-u-section-1" id="sec-ba03">
            <div className="u-clearfix u-sheet u-sheet-1">
                <div className="fr-view u-align-center u-clearfix u-rich-text u-text u-text-1" style={{fontSize: '1rem', fontFamily: 'Open Sans,sans-serif'}}>
                    <p>
                        <br />
                    </p>
                    <p id="isPasted"><i>Inspired by Vogue. Style. Fashion. <b>"Everyone has self worth, no matter the style. No matter how BOUJEE."</b></i></p>
                    <p>The first of four generations of Vogies NFT are here</p>
                    <p>Megzie, the artist, brought the Vogies vision to life with her attention to detail pertaining to each NFT</p>
                    <p>The artist would love to do all different kinds of physical prints, clothing, and more as we go!</p>
                    <p>
                        <br />
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        <span style={{ lineHeight: 2.0 }}>
                            <img src={vogie} style={{ width: '583px' }} className="fr-dib fr-fic" width={570} align="middle" />
                        </span>
                    </p>
                    <p>
                        <br />
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        <span style={{ lineHeight: 2.0 }}>The PFP collection brought to you by the team; <a  style={{textDecoration: 'underline'}} href={'https://twitter.com/Dj_Burnz'}>Dj Burnz</a>, <a  style={{textDecoration: 'underline'}} href={'https://twitter.com/UkiyoeWarriors'}>Airworthy</a>, <a style={{textDecoration: 'underline'}} href={'https://twitter.com/MegzieStudio'}>Megzie</a> and <a style={{textDecoration: 'underline'}} href={'https://twitter.com/novocaineee'}>Novocaine</a>.</span>
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        <span style={{ lineHeight: 2.0 }}>With only a 1,000 NFTs, we strive to bring value to the HODLers and collectors</span>
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        <span style={{ lineHeight: 2.0 }}>Vogies are exclusively walking the runway on <span style={{ fontWeight: 700 }}>VeChain</span>. &nbsp;
                        </span>
                    </p>
                </div>
                <div className="u-border-6 u-border-custom-color-1 u-shape u-shape-bottom u-shape-right u-shape-1" />
                <a href="/minting" className="u-border-none u-btn u-button-style u-gradient u-none u-text-body-alt-color u-btn-1">To minting page</a>
            </div>
        </section>
        <section className="u-clearfix home-u-section-2" id="sec-91be">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-align-center u-custom-font u-text u-text-default u-text-1">Roadmap</h1>
                <img className="u-expanded-width u-image u-image-default u-image-1" src={roadmap} alt="" data-image-width={1600} data-image-height={1084} />
            </div>
        </section>
        <section className="u-align-center u-clearfix home-u-section-3" id="sec-dfec">
            <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
                <h2 className="u-custom-font u-text u-text-default u-text-1">faq</h2>
                <div className="u-accordion u-faq u-spacing-20 u-accordion-1">
                    <div className="u-accordion-item">
                        <a className="active u-accordion-link u-border-1 u-border-active-grey-75 u-border-grey-15 u-border-hover-grey-75 u-border-no-left u-border-no-right u-border-no-top u-button-style u-text-black u-accordion-link-1" id="link-accordion-6327" aria-controls="accordion-6327" aria-selected="true">
                            <span className="u-accordion-link-text"> What is an NFT?</span><span className="u-accordion-link-icon u-icon u-text-black u-icon-1"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-8021" /></svg><svg className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-8021" style={{}}><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
            c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z" /></svg></span>
                        </a>
                        <div className="u-accordion-active u-accordion-pane u-container-style u-accordion-pane-1" id="accordion-6327" aria-labelledby="link-accordion-6327">
                            <div className="u-container-layout u-container-layout-1">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>NFT stands for "non-fungible token". An NFT is basically data that is accounted for in a digital ledger, and that data represents something specific and unique. An NFT can, for example, represent a unique piece of art or a game token. Because of this, NFTs have collectible value. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-border-1 u-border-active-grey-75 u-border-grey-15 u-border-hover-grey-75 u-border-no-left u-border-no-right u-border-no-top u-button-style u-text-black u-accordion-link-2" id="link-accordion-4aab" aria-controls="accordion-4aab" aria-selected="false">
                            <span className="u-accordion-link-text"> How can I buy a Vogies NFT?</span><span className="u-accordion-link-icon u-icon u-text-black u-icon-2"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-3470" /></svg><svg className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-3470" style={{}}><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
            c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z" /></svg></span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-accordion-pane-2" id="accordion-4aab" aria-labelledby="link-accordion-4aab">
                            <div className="u-container-layout u-container-layout-2">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>The first step in purchasing and holding a Vogies NFT, is to create a VeChainThor wallet like Sync2/VeChainThor/Comet Wallet that you can connect to our Dapp. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-border-1 u-border-active-grey-75 u-border-grey-15 u-border-hover-grey-75 u-border-no-left u-border-no-right u-border-no-top u-button-style u-text-black u-accordion-link-3" id="link-accordion-eb1f" aria-controls="accordion-eb1f" aria-selected="false">
                            <span className="u-accordion-link-text">What blockchain will you be using?</span><span className="u-accordion-link-icon u-icon u-text-black u-icon-3"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-e07d" /></svg><svg className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-e07d" style={{}}><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
            c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z" /></svg></span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-accordion-pane-3" id="accordion-eb1f" aria-labelledby="link-accordion-eb1f">
                            <div className="u-container-layout u-container-layout-3">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>Vogies exists on the VeChain blockchain. The NFTs are minted, bought and sold exclusively on the Black Ve Market. They cannot be found in other VeChain marketplaces, or on any blockchain other than VeChain. Were you to discover someone posting a Vogie on a different blockchain or marketplace as their own work, please report it to the original creators. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-border-1 u-border-active-grey-75 u-border-grey-15 u-border-hover-grey-75 u-border-no-left u-border-no-right u-border-no-top u-button-style u-text-black u-accordion-link-4" id="link-3d8e" aria-controls="3d8e" aria-selected="false">
                            <span className="u-accordion-link-text"> What does “MINT” mean?</span><span className="u-accordion-link-icon u-icon u-text-black u-icon-4"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-6123" /></svg><svg className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-6123"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
            c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z" /></svg></span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-accordion-pane-4" id="3d8e" aria-labelledby="link-3d8e">
                            <div className="u-container-layout u-container-layout-4">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>“Minting” an NFT is, in more simple terms, uniquely publishing a token on the blockchain to make it purchasable. Each NFT minted will have its own unique “Mint number” that proves it is one of the collection.<br id="isPasted" />
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-border-1 u-border-active-grey-75 u-border-grey-15 u-border-hover-grey-75 u-border-no-left u-border-no-right u-border-no-top u-button-style u-text-black u-accordion-link-5" id="link-8152" aria-controls={8152} aria-selected="false">
                            <span className="u-accordion-link-text"> How many NFTs will there be?</span><span className="u-accordion-link-icon u-icon u-text-black u-icon-5"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-e89c" /></svg><svg className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-e89c"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
            c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z" /></svg></span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-accordion-pane-5" id={8152} aria-labelledby="link-8152">
                            <div className="u-container-layout u-container-layout-5">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>At the time of release, there will be 1000 unique Vogie pfp NFTs. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-border-1 u-border-active-grey-75 u-border-grey-15 u-border-hover-grey-75 u-border-no-left u-border-no-right u-border-no-top u-button-style u-text-black u-accordion-link-6" id="link-387f" aria-controls="387f" aria-selected="false">
                            <span className="u-accordion-link-text"> How much does it cost to buy a Vogies NFT?</span><span className="u-accordion-link-icon u-icon u-text-black u-icon-6"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-2dc4" /></svg><svg className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-2dc4"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
            c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z" /></svg></span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-accordion-pane-6" id="387f" aria-labelledby="link-387f">
                            <div className="u-container-layout u-container-layout-6">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>When released, minting a Vogie cost 500 VET. After all Vogies are minted, the purchase price will depend on the floor price in the secondary market. In other words, the price of an individual Vogie NFT will be determined by collectors who choose to sell their Vogie. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
};

export default Home;

import { USER_ADDRESS } from "../constants";
import {SpacedOutABI} from "../constants/spacedoutabi";
import { useState, useEffect } from 'react';
import { connex, spacedoutcontract } from "../constants/contract"
import InputSpinner from 'react-bootstrap-input-spinner'
import BigNumber from "bignumber.js";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify';
import { css } from "@emotion/react";
import vogie1 from '../images/Dancer_Vogie_transparent_background.png'
import vogie2 from '../images/Queen_AW_Transparent.png'
import {sleep,getReceipt} from "../utilities/util";
import spacedOutAssets from "./SpacedOutAssets";

const Minting = () => {
    const [totalSupply, setTotalSupply] = useState(0);
    const [mintedAmount, setMintedAmount] = useState(0);
    const [mintPrice, setMintPrice] = useState(0);
    const [mintAmount, setMintAmount] = useState(1);
    const [currentMintInfo, setCurrentMintInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [mintsLeft, setmintsLeft] = useState(0)

    const findMethodABI = (abi, method) => abi[abi.findIndex(mthd => mthd.name === method)]

    useEffect(async () => {
        await initialLoad();
    }, [])


    const initialLoad = async () => {
        const totalMintedABI = findMethodABI(SpacedOutABI, 'totalSupply');
        const totalMintedMethod = await spacedoutcontract.method(totalMintedABI).call();
        setTotalSupply(parseInt(totalMintedMethod.decoded[0]));

        const availableTokenCountABI = findMethodABI(SpacedOutABI, 'availableTokenCount');
        const availableTokenCountMethod = await spacedoutcontract.method(availableTokenCountABI).call();
        setMintedAmount(parseInt(availableTokenCountMethod.decoded[0]));

        const getCurrentSaleTypeABI = findMethodABI(SpacedOutABI, 'getCurrentSaleType');
        const getCurrentSaleTypeMethod = await spacedoutcontract.method(getCurrentSaleTypeABI).call();
        const currentSaleType = getCurrentSaleTypeMethod.decoded[0];

        let currentSalesInfo = {
            maxTokens: 0,
            period: 0,
            price: 0,
            salesType: 0
        }
        if (currentSaleType !== undefined) {
            const getSalesInfoABI = findMethodABI(SpacedOutABI, 'saleInfo');
            const getSalesInfo = await spacedoutcontract.method(getSalesInfoABI).call(currentSaleType);
            currentSalesInfo = getSalesInfo.decoded;

            console.log(currentSalesInfo);
        }
        setCurrentMintInfo(currentSalesInfo);

        setMintPrice(currentSalesInfo['price']);
        setLoading(false);
    }

    const mint = async () => {
        if (!USER_ADDRESS) {
            toast.error('Connect your wallet!', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
            });

            return;
        }

        const mintABI = findMethodABI(SpacedOutABI, 'mint');
        const mintMethod = spacedoutcontract.method(mintABI).caller(USER_ADDRESS);

        const totalPrice = new BigNumber(mintPrice * mintAmount).toFixed();
        const clause = mintMethod.value(totalPrice).asClause(mintAmount)

        const resp = await connex
                .vendor
                .sign('tx', [clause])
                .comment('Transaction signing - Mint NFT')
                .accepted(() => console.log('accepted'))
                .request()
            ;

        setLoading(true);

        let receipt = null;
        while (receipt == null) {
            receipt = await getReceipt(resp.txid);
            await sleep(1000);
        }

        toast.success('Minting succeeded!', {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
        });
        setmintsLeft(0)

        await initialLoad();

        setLoading(false);
    }

    const override = css`
        display: block;
        margin: 0 auto;
    `;

    if (loading) {
        return <ClipLoader color={'#0000000'} loading={loading} css={override} size={40} />
    }

    import('./../Minting.css')

    return <section className="u-clearfix minting-u-section-1" id="sec-bd2f">
        <div className="u-clearfix u-sheet u-sheet-1">
            <h1 className="u-align-center u-custom-font u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xl u-title u-text-1">Get your Vogie</h1>
            <img className="u-image u-image-default u-preserve-proportions u-image-1" src={vogie1} alt="" data-image-width={3000} data-image-height={3000} />
            <img className="u-hidden-md u-hidden-sm u-hidden-xs u-image u-image-default u-preserve-proportions u-image-2" src={vogie2} alt="" data-image-width={3000} data-image-height={3000} />
            <h4 className="u-align-center-xs u-text u-text-default u-text-2">How much Spaced Out Vogies do you want ?</h4>
            <p className="u-align-center u-text u-text-3">
                OG : 500 VET (6 Per TX)<br />
                Whitelist : 500 VET (4 Per TX)<br />
                Public sale : 500 VET (10 Per TX)
            </p>
            <p className="u-align-center u-text u-text-3">
                Current situation: <br />
                You can still mint: {currentMintInfo['maxTokens']}  <br />
                {(mintPrice * 1) > 0 ? 'For  ' + (mintPrice * 1) / ("1e" + 18) + ' VET' : 'Minting disabled' }
            </p>
            <p className="u-align-center u-text u-text-4"> Start PreSale: May 23th @ (5 PM UTC OG) (6PM-WL)<br />Start Public sale : May 23th @ 7 PM UTC<br />
            </p>

            <p className="u-text u-text-default u-text-5">Vogies still available {mintedAmount}/{totalSupply}</p>
            <div className="u-form u-form-1">
                <div className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form" source="custom" name="form" style={{ padding: '10px' }}>
                    <div className="u-form-email u-form-group">
                        <label htmlFor="email-06a3" className="u-label">Amount</label>
                        <input disabled={currentMintInfo['maxTokens'] <= 0 ? 1 : 0} type="number" value={mintAmount} max={currentMintInfo['maxTokens']} min={1} onChange={num=>setMintAmount(Number(num.target.value))} name="Amount" className="u-border-1 u-border-grey-30 u-input u-input-rectangle u-white" required />
                    </div>
                    <div className="u-align-center u-form-group u-form-submit">
                        <button onClick={mint} className="u-btn u-btn-submit u-button-style" disabled={currentMintInfo['maxTokens'] <= 0 ? 1 : 0}>{(mintPrice * 1) > 0 ? 'Mint' : 'Minting disabled' }</button>
                        <input type="submit" defaultValue="submit" className="u-form-control-hidden" />
                    </div>
                </div>
            </div>
        </div>
    </section>
};

export default Minting;
